.DayPicker__withBorder {
  background: #fff;
  box-shadow: 0 4px 24px -6px rgba(44, 62, 80, 0.25);
  border-radius: 5px;
}

.DateInput_input,
.DateInput_input__focused,
.SingleDatePickerInput__withBorder {
  padding: 0;
}

.DateInput,
.SingleDatePickerInput {
  background: inherit;
}

.DateInput_input {
  font: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  color: #2c3e50;
  border: inherit;
  background: inherit;
}

.filterContainer .DateInput_input {
  font-size: 14px;
}

.CalendarMonth_caption {
  font-size: 16px;
  color: #2c3e50;
  letter-spacing: 0.2px;
  text-align: center;
}

.CalendarDay {
  border: none;
  vertical-align: middle;
  border: none;
  opacity: 0.75;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 0.28px;
}

.CalendarDay {
  width: 46px !important;
  height: 33px !important;
}

.CalendarDay__blocked_out_of_range {
  color: #8a96a2;
}

.CalendarMonth {
  padding: 0px !important;
}

.CalendarMonth_table {
  margin-top: 5px;
}

.CalendarDay__default:hover,
.CalendarDay__default:focus {
  border: none;
  color: #01cbff;
  background-color: #fff;
  background-image: linear-gradient(
    -135deg,
    rgba(1, 203, 255, 0.15) 0%,
    rgba(0, 167, 231, 0.15) 100%
  );
  outline: 0;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #fff;
  color: #4d5b6a;
  font-weight: bold;
  position: relative;
}

.CalendarDay__selected::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #01cbff;
  position: absolute;
  top: 0;
  left: 0;
}

.CalendarDay__selected::before {
  left: 6px;
  width: 34px;
  height: 33px;
}

.DayPickerNavigation {
  width: 580px;
  margin-left: 20px;
}

.filterContainer .DayPickerNavigation {
  width: 320px !important;
  margin-left: 10px;
}

.DayPickerNavigation_button__default,
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: none;
}

.DateInput_fang,
.SingleDatePicker_picker {
  z-index: 2;
  margin-top: -20px;
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background-color: transparent;
  border-radius: 0;
  outline: 0;
}

.CalendarMonth_caption {
  border-bottom: 2px solid #e9ebed;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 14px;
}

.filterContainer .DayPicker__withBorder {
  box-shadow: none;
}

.filterContainer .DateInput_fang {
  display: none;
}

.SingleDatePicker_picker {
  margin-left: -26px;
}

.CalendarMonth_table {
  margin-top: 43px;
}

.DayPicker {
  width: 665px !important;
}

.DayPicker_transitionContainer {
  width: 665px !important;
}

.DayPicker_weekHeader {
  top: 67px;
}

.DayPicker_weekHeader:nth-child(2) {
  left: 322px !important;
}

.DayPickerNavigation {
  width: 643px !important;
  margin-left: 10px;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 0;
  padding: 6px 0px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 0;
  padding: 6px 0px;
}

.DayPicker_weekHeader {
  padding: 0px !important;
  white-space: nowrap;
}

.DayPicker_weekHeader_li,
.CalendaryDay {
  height: 33px !important;
  width: 46px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DayPicker_weekHeader_li {
  font-size: 12px;
  font-weight: 500;
  color: #2c3e50;
}

.CalendarDay {
  font-weight: normal;
  color: #8a96a2;
}

.filterContainer .DayPicker_transitionContainer {
  height: 330px !important;
  overflow: inherit;
}
